export async function checkIfSignedIn() {
    let data = await fetch('https://server.ratemyclasses.net', {
        method: "get",
        credentials: 'include'
    }, []);
    let response = await data.json();
    let signedIn = response.isSignedIn;
    return signedIn;
}

// module.exports = { checkIfSignedIn };
