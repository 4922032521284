import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar.js';

export default function Home() {

    //USENAVIGATE HOOK
    let navigate = useNavigate();


    //RETURNED COMPONENT
    return (
	<div>
	    <meta name="og:site_name" content="Rate My Classes" />
            <meta name="og:url" content="https://ratemyclasses.net" />
	    <meta name="og:title" content="Rate My Classes" />
	    <meta name="og:description" content="Unofficial class review site for Rutgers students" />
	    <meta name="og:type" content="website" />
            <meta name="description" content="Unofficial class review site for Rutgers students" />
    	    
	    <div className="home-bg"></div>

            <Navbar/>

            <span className="home-mainContainer">
                <div className="home-buttonSectionContainer">
                <div className="home-buttonSection">
                    <button className="home-button" onClick={() => navigate('/courses')}>SEARCH COURSES</button>
                    <p className="home-paragraph">
                    Search for classes and reviews already submitted to the database. No login necessary.
                    </p>
                </div>
                <div className="home-buttonSection">
                    <button className="home-button" onClick={() => navigate('/review')}>WRITE A REVIEW</button>
                    <p className="home-paragraph">
                    Write a review for a class whether it already exists in the database or not. Login required.
                    </p>
                </div>
            </div>

            </span>
            
            <div className="footer">
                <div className="about-text" onClick={() => navigate('/about')}>about page</div>
            </div>
        </div>
    );
}
